/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start pt-4>
    <template v-if="!profileEdit">
      <v-flex xs12>
        <span class="subtitle-2"> {{ $t("form.name") }} </span> <br />
        <span class="title-1"> {{ profile.name }} </span>
        <br />
        <AppButton
          small
          label="pages.settings.edit_profile"
          @submit="toggleProfileEdit"
        />
      </v-flex>
      <v-flex xs12 lg8 pt-8>
        <v-card outlined class="app-border-color">
          <v-layout wrap justify-start pa-4>
            <v-flex xs6 lg4>
              <span class="subtitle-2"> {{ $t("form.email") }} </span><br />
              <span class="title-3">{{ profile.email }}</span>
            </v-flex>
            <v-flex xs6 lg4>
              <span class="subtitle-2">
                {{ $t("pages.settings.added_date") }}
              </span>
              <br />
              <span class="title-3"> {{ createdDate }} </span>
            </v-flex>
            <v-flex xs6 lg4>
              <span class="subtitle-2">{{ $t("form.mobile_number") }} </span>
              <br />
              <span class="title-3">{{ getPhone }}</span>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </template>
    <template v-else>
      <v-flex xs12 lg6>
        <v-form ref="profile_form" lazy-validation>
          <v-layout wrap justify-start>
            <v-flex xs12 lg6 pa-lg-2 align-self-center>
              <p class="input-label">{{ $t("form.name") }}</p>
              <v-text-field
                dense
                outlined
                v-model="name"
                :rules="[RULES.REQUIRED]"
              />
            </v-flex>
            <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-start>
              <p class="input-label">{{ $t("form.mobile_number") }}</p>
              <v-text-field
                dense
                outlined
                v-model="phone"
                :rules="[RULES.REQUIRED]"
              />
            </v-flex>
            <v-flex xs12 lg6 pt-4 pa-lg-2 align-self-center>
              <p class="input-label">{{ $t("form.address") }}</p>
              <v-textarea
                rows="4"
                dense
                outlined
                v-model="address"
                :rules="[RULES.REQUIRED]"
              />
            </v-flex>
          </v-layout>
        </v-form>
        <v-layout wrap justify-start>
          <v-flex xs12 lg12 text-right>
            <AppButton label="Save" @submit="updateProfile" />
          </v-flex>
        </v-layout>
      </v-flex>
    </template>
    <v-flex xs12 pt-8>
      <span class="title-2">
        {{ $t("pages.settings.password_management") }}
      </span>
    </v-flex>
    <v-flex xs12 :lg8="showNewPwd" :lg6="!showNewPwd" xl6 pt-2>
      <v-form ref="password_form" lazy-validation>
        <v-card outlined class="app-border-color">
          <v-layout wrap justify-start pa-4>
            <v-flex xs12 sm4 pr-4>
              <p class="input-label">{{ $t("form.current_password") }}</p>
              <v-text-field
                v-model="current_password"
                placeholder="**********************"
                :type="viewCurrentPassword ? 'text' : 'password'"
                dense
                outlined
                :rules="[RULES.REQUIRED]"
                :append-icon="viewCurrentPassword ? `mdi-eye` : 'mdi-eye-off'"
                @click:append="viewCurrentPassword = !viewCurrentPassword"
              />
            </v-flex>
            <v-flex xs12 sm4 pt-4 pt-sm-0 pr-4 v-if="showNewPwd">
              <p class="input-label">{{ $t("form.new_password") }}</p>
              <v-text-field
                placeholder="**********************"
                :type="viewPassword ? 'text' : 'password'"
                :append-icon="viewPassword ? `mdi-eye` : 'mdi-eye-off'"
                dense
                outlined
                v-model="password"
                :rules="[RULES.REQUIRED]"
                @click:append="viewPassword = !viewPassword"
              />
            </v-flex>
            <v-flex xs12 sm4 pt-4 pt-sm-0 pr-4 v-if="showNewPwd">
              <p class="input-label">{{ $t("form.confirm_password") }}</p>
              <v-text-field
                placeholder="**********************"
                :type="viewConfirmPassword ? 'text' : 'password'"
                :append-icon="viewConfirmPassword ? `mdi-eye` : 'mdi-eye-off'"
                dense
                outlined
                v-model="password_confirmation"
                :rules="[RULES.REQUIRED, RULES.EQUALS(password)]"
                @click:append="viewConfirmPassword = !viewConfirmPassword"
              />
            </v-flex>
            <v-flex xs12 v-if="showNewPwd" py-4>
              <v-divider />
            </v-flex>
            <v-flex xs12 v-if="showNewPwd" py-4 text-right align-self-center>
              <span class="px-4">
                <AppNavButton color="primary" custom-class="pa-5" outlined>
                  <template #content>
                    <span style="color: #3278ff">
                      {{ $t("buttons.cancel") }}
                    </span>
                  </template>
                </AppNavButton>
              </span>
              <span class="px-4">
                <AppButton
                  custom-class="py-5"
                  label="pages.settings.change_password"
                  @submit="changePassword"
                />
              </span>
            </v-flex>
            <v-flex
              xs4
              lg4
              pt-4
              pt-lg-0
              align-self-center
              mb-lg-2
              v-if="!showNewPwd"
            >
              <AppButton
                block
                custom-class="py-5"
                label="pages.settings.change_password"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-form>
    </v-flex>
    <v-flex xs12>
      <v-layout wrap justify-start pt-8>
        <v-flex xs12>
          <span class="title-2"> {{ $t("pages.settings.language") }} </span>
        </v-flex>
        <v-flex xs12 sm2 pt-4 align-self-center>
          <!--          <p class="input-label">-->
          <!--            {{ $t("words.select") + ` ` + $t("pages.settings.language") }}-->
          <!--          </p>-->
          <v-select
            :placeholder="
              $t('words.select') + ` ` + $t('pages.settings.language')
            "
            :items="languages"
            v-model="language"
            item-text="label"
            item-value="value"
            dense
            hide-details
            outlined
          />
        </v-flex>
        <v-flex xs12 sm2 px-4 pt-4 align-self-center>
          <AppButton
            custom-class="py-5"
            label="pages.settings.change_language"
            @submit="changeLanguage"
          />
        </v-flex>
      </v-layout>
      <v-layout wrap justify-start pt-8 v-show="showTimezone">
        <v-flex xs12>
          <span class="title-2"> {{ $t("pages.settings.timezone") }} </span>
        </v-flex>
        <v-flex xs12 sm2 pt-4 align-self-center>
          <p class="input-label">
            {{ $t("words.select") + ` ` + $t("pages.settings.timezone") }}
          </p>
          <v-combobox
            :placeholder="
              $t('words.select') + ` ` + $t('pages.settings.timezone')
            "
            :items="timezones"
            v-model="timezone"
            item-text="text"
            item-value="key"
            dense
            hide-details
            outlined
          />
        </v-flex>
        <v-flex xs12 sm2 px-4 pt-8 align-self-center>
          <AppButton
            custom-class="py-5"
            label="pages.settings.change_timezone"
            @submit="changeTimezone"
          />
        </v-flex>
      </v-layout>
    </v-flex>

    <SuccessConfirmation
      v-model="showSuccess"
      :title="successTitle"
      @submit="toggleSuccess(successType)"
    />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { getLocal, setLocal } from "@/libs/local";
import { RULES } from "@/libs/helper/components/rules";
import moment from "moment";

export default {
  name: "Profile",
  data: () => ({
    RULES,
    showSuccess: false,
    successTitle: "Password Updated",
    profileEdit: false,
    current_password: null,
    password: null,
    password_confirmation: null,
    language: localStorage.getItem("lang"),

    name: null,
    phone: null,
    address: null,
    timezone: getLocal("profile").default_timezone,
    successType: null,
    viewCurrentPassword: false,
    viewPassword: false,
    viewConfirmPassword: false,
  }),
  computed: {
    ...mapGetters({
      profile: "app/getProfile",
      timezones: "app/getTimezones",
    }),
    languages() {
      return [
        { label: this.$t("languages.english"), value: "en" },
        { label: this.$t("languages.german"), value: "de" },
      ];
    },
    createdDate() {
      return this.$moment(this.profile?.hospital?.created_at).format(
        "DD MMM YYYY"
      );
    },
    showNewPwd() {
      return this.current_password?.length;
    },
    userType() {
      return localStorage.getItem("type");
    },
    getPhone() {
      return this.profile.hospital?.phone ?? null;
    },
    showTimezone() {
      return !!getLocal("showTimezone");
    },
  },
  created() {
    this.$store.dispatch("app/getProfile").then((res) => {
      this.name = res.hospital?.name;
      this.phone = res.hospital?.phone;
      this.address = res.hospital?.address;
    });
  },
  mounted() {
    const breadCrumbs = [
      {
        name: "settings",
        text: "pages.settings.title",
      },
      {
        name: this.$route.name,
        text: "pages.settings.profile",
      },
    ];
    this.$store.commit("app/setBreadCrumbs", breadCrumbs);
  },
  methods: {
    toggleProfileEdit() {
      this.profileEdit = !this.profileEdit;
    },
    toggleSuccess(type) {
      this.successType = type;
      switch (type) {
        case "lang":
          if (this.showSuccess) {
            this.successType = null;
            window.location.reload();
          }
      }
      this.showSuccess = !this.showSuccess;
    },
    updateProfile() {
      if (!this.$refs.profile_form.validate()) return;
      const data = {
        name: this.name,
        phone: this.phone,
        address: this.address,
      };
      this.$store
        .dispatch("app/updateProfile", data)
        .then(() => {
          this.successTitle = "Profile Updated";
          this.toggleProfileEdit();
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
    changePassword() {
      if (!this.$refs.password_form.validate()) return;
      const data = {
        current_password: this.current_password,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };
      this.$store
        .dispatch("app/changePassword", data)
        .then(() => {
          this.successTitle = this.$t("pages.settings.password_changed");
          this.$refs.password_form.reset();
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
    changeLanguage() {
      setLocal("lang", this.language);
      moment.locale(this.language);
      this.$i18n.locale = this.language;
      this.$store
        .dispatch("app/changeLanguage")
        .then(() => {
          this.$store.commit("app/setLang", this.language);
          this.successTitle = this.$t("pages.settings.language_changed");
          this.toggleSuccess("lang");
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
    changeTimezone() {
      const data = { timezone: this.timezone.key };
      this.$store
        .dispatch("app/changeTimezone", data)
        .then((res) => {
          console.log(res);
          this.successTitle = "Timezone Changed";
          this.toggleSuccess();
        })
        .catch((err) => {
          console.log(err);
          this.message = err.response.data.message;
          this.snackbarColor = "error";
          this.showSnackbar = true;
        });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
