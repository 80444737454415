var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "pt-4": "" } },
    [
      !_vm.profileEdit
        ? [
            _c(
              "v-flex",
              { attrs: { xs12: "" } },
              [
                _c("span", { staticClass: "subtitle-2" }, [
                  _vm._v(" " + _vm._s(_vm.$t("form.name")) + " "),
                ]),
                _vm._v(" "),
                _c("br"),
                _c("span", { staticClass: "title-1" }, [
                  _vm._v(" " + _vm._s(_vm.profile.name) + " "),
                ]),
                _c("br"),
                _c("AppButton", {
                  attrs: { small: "", label: "pages.settings.edit_profile" },
                  on: { submit: _vm.toggleProfileEdit },
                }),
              ],
              1
            ),
            _c(
              "v-flex",
              { attrs: { xs12: "", lg8: "", "pt-8": "" } },
              [
                _c(
                  "v-card",
                  { staticClass: "app-border-color", attrs: { outlined: "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "justify-start": "", "pa-4": "" } },
                      [
                        _c("v-flex", { attrs: { xs6: "", lg4: "" } }, [
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(" " + _vm._s(_vm.$t("form.email")) + " "),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "title-3" }, [
                            _vm._v(_vm._s(_vm.profile.email)),
                          ]),
                        ]),
                        _c("v-flex", { attrs: { xs6: "", lg4: "" } }, [
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("pages.settings.added_date")) +
                                " "
                            ),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "title-3" }, [
                            _vm._v(" " + _vm._s(_vm.createdDate) + " "),
                          ]),
                        ]),
                        _c("v-flex", { attrs: { xs6: "", lg4: "" } }, [
                          _c("span", { staticClass: "subtitle-2" }, [
                            _vm._v(_vm._s(_vm.$t("form.mobile_number")) + " "),
                          ]),
                          _c("br"),
                          _c("span", { staticClass: "title-3" }, [
                            _vm._v(_vm._s(_vm.getPhone)),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : [
            _c(
              "v-flex",
              { attrs: { xs12: "", lg6: "" } },
              [
                _c(
                  "v-form",
                  { ref: "profile_form", attrs: { "lazy-validation": "" } },
                  [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "", "justify-start": "" } },
                      [
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              lg6: "",
                              "pa-lg-2": "",
                              "align-self-center": "",
                            },
                          },
                          [
                            _c("p", { staticClass: "input-label" }, [
                              _vm._v(_vm._s(_vm.$t("form.name"))),
                            ]),
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                rules: [_vm.RULES.REQUIRED],
                              },
                              model: {
                                value: _vm.name,
                                callback: function ($$v) {
                                  _vm.name = $$v
                                },
                                expression: "name",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              lg6: "",
                              "pt-4": "",
                              "pa-lg-2": "",
                              "align-self-start": "",
                            },
                          },
                          [
                            _c("p", { staticClass: "input-label" }, [
                              _vm._v(_vm._s(_vm.$t("form.mobile_number"))),
                            ]),
                            _c("v-text-field", {
                              attrs: {
                                dense: "",
                                outlined: "",
                                rules: [_vm.RULES.REQUIRED],
                              },
                              model: {
                                value: _vm.phone,
                                callback: function ($$v) {
                                  _vm.phone = $$v
                                },
                                expression: "phone",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          {
                            attrs: {
                              xs12: "",
                              lg6: "",
                              "pt-4": "",
                              "pa-lg-2": "",
                              "align-self-center": "",
                            },
                          },
                          [
                            _c("p", { staticClass: "input-label" }, [
                              _vm._v(_vm._s(_vm.$t("form.address"))),
                            ]),
                            _c("v-textarea", {
                              attrs: {
                                rows: "4",
                                dense: "",
                                outlined: "",
                                rules: [_vm.RULES.REQUIRED],
                              },
                              model: {
                                value: _vm.address,
                                callback: function ($$v) {
                                  _vm.address = $$v
                                },
                                expression: "address",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-layout",
                  { attrs: { wrap: "", "justify-start": "" } },
                  [
                    _c(
                      "v-flex",
                      { attrs: { xs12: "", lg12: "", "text-right": "" } },
                      [
                        _c("AppButton", {
                          attrs: { label: "Save" },
                          on: { submit: _vm.updateProfile },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
      _c("v-flex", { attrs: { xs12: "", "pt-8": "" } }, [
        _c("span", { staticClass: "title-2" }, [
          _vm._v(
            " " + _vm._s(_vm.$t("pages.settings.password_management")) + " "
          ),
        ]),
      ]),
      _c(
        "v-flex",
        {
          attrs: {
            xs12: "",
            lg8: _vm.showNewPwd,
            lg6: !_vm.showNewPwd,
            xl6: "",
            "pt-2": "",
          },
        },
        [
          _c(
            "v-form",
            { ref: "password_form", attrs: { "lazy-validation": "" } },
            [
              _c(
                "v-card",
                { staticClass: "app-border-color", attrs: { outlined: "" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { wrap: "", "justify-start": "", "pa-4": "" } },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm4: "", "pr-4": "" } },
                        [
                          _c("p", { staticClass: "input-label" }, [
                            _vm._v(_vm._s(_vm.$t("form.current_password"))),
                          ]),
                          _c("v-text-field", {
                            attrs: {
                              placeholder: "**********************",
                              type: _vm.viewCurrentPassword
                                ? "text"
                                : "password",
                              dense: "",
                              outlined: "",
                              rules: [_vm.RULES.REQUIRED],
                              "append-icon": _vm.viewCurrentPassword
                                ? `mdi-eye`
                                : "mdi-eye-off",
                            },
                            on: {
                              "click:append": function ($event) {
                                _vm.viewCurrentPassword =
                                  !_vm.viewCurrentPassword
                              },
                            },
                            model: {
                              value: _vm.current_password,
                              callback: function ($$v) {
                                _vm.current_password = $$v
                              },
                              expression: "current_password",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.showNewPwd
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm4: "",
                                "pt-4": "",
                                "pt-sm-0": "",
                                "pr-4": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.new_password"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "**********************",
                                  type: _vm.viewPassword ? "text" : "password",
                                  "append-icon": _vm.viewPassword
                                    ? `mdi-eye`
                                    : "mdi-eye-off",
                                  dense: "",
                                  outlined: "",
                                  rules: [_vm.RULES.REQUIRED],
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.viewPassword = !_vm.viewPassword
                                  },
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showNewPwd
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                sm4: "",
                                "pt-4": "",
                                "pt-sm-0": "",
                                "pr-4": "",
                              },
                            },
                            [
                              _c("p", { staticClass: "input-label" }, [
                                _vm._v(_vm._s(_vm.$t("form.confirm_password"))),
                              ]),
                              _c("v-text-field", {
                                attrs: {
                                  placeholder: "**********************",
                                  type: _vm.viewConfirmPassword
                                    ? "text"
                                    : "password",
                                  "append-icon": _vm.viewConfirmPassword
                                    ? `mdi-eye`
                                    : "mdi-eye-off",
                                  dense: "",
                                  outlined: "",
                                  rules: [
                                    _vm.RULES.REQUIRED,
                                    _vm.RULES.EQUALS(_vm.password),
                                  ],
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.viewConfirmPassword =
                                      !_vm.viewConfirmPassword
                                  },
                                },
                                model: {
                                  value: _vm.password_confirmation,
                                  callback: function ($$v) {
                                    _vm.password_confirmation = $$v
                                  },
                                  expression: "password_confirmation",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.showNewPwd
                        ? _c(
                            "v-flex",
                            { attrs: { xs12: "", "py-4": "" } },
                            [_c("v-divider")],
                            1
                          )
                        : _vm._e(),
                      _vm.showNewPwd
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                xs12: "",
                                "py-4": "",
                                "text-right": "",
                                "align-self-center": "",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "px-4" },
                                [
                                  _c("AppNavButton", {
                                    attrs: {
                                      color: "primary",
                                      "custom-class": "pa-5",
                                      outlined: "",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "content",
                                          fn: function () {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    color: "#3278ff",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("buttons.cancel")
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      1174579661
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "px-4" },
                                [
                                  _c("AppButton", {
                                    attrs: {
                                      "custom-class": "py-5",
                                      label: "pages.settings.change_password",
                                    },
                                    on: { submit: _vm.changePassword },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      !_vm.showNewPwd
                        ? _c(
                            "v-flex",
                            {
                              attrs: {
                                xs4: "",
                                lg4: "",
                                "pt-4": "",
                                "pt-lg-0": "",
                                "align-self-center": "",
                                "mb-lg-2": "",
                              },
                            },
                            [
                              _c("AppButton", {
                                attrs: {
                                  block: "",
                                  "custom-class": "py-5",
                                  label: "pages.settings.change_password",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-flex",
        { attrs: { xs12: "" } },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-start": "", "pt-8": "" } },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "title-2" }, [
                  _vm._v(" " + _vm._s(_vm.$t("pages.settings.language")) + " "),
                ]),
              ]),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm2: "",
                    "pt-4": "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("v-select", {
                    attrs: {
                      placeholder:
                        _vm.$t("words.select") +
                        ` ` +
                        _vm.$t("pages.settings.language"),
                      items: _vm.languages,
                      "item-text": "label",
                      "item-value": "value",
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.language,
                      callback: function ($$v) {
                        _vm.language = $$v
                      },
                      expression: "language",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm2: "",
                    "px-4": "",
                    "pt-4": "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("AppButton", {
                    attrs: {
                      "custom-class": "py-5",
                      label: "pages.settings.change_language",
                    },
                    on: { submit: _vm.changeLanguage },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showTimezone,
                  expression: "showTimezone",
                },
              ],
              attrs: { wrap: "", "justify-start": "", "pt-8": "" },
            },
            [
              _c("v-flex", { attrs: { xs12: "" } }, [
                _c("span", { staticClass: "title-2" }, [
                  _vm._v(" " + _vm._s(_vm.$t("pages.settings.timezone")) + " "),
                ]),
              ]),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm2: "",
                    "pt-4": "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("p", { staticClass: "input-label" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("words.select") +
                            ` ` +
                            _vm.$t("pages.settings.timezone")
                        ) +
                        " "
                    ),
                  ]),
                  _c("v-combobox", {
                    attrs: {
                      placeholder:
                        _vm.$t("words.select") +
                        ` ` +
                        _vm.$t("pages.settings.timezone"),
                      items: _vm.timezones,
                      "item-text": "text",
                      "item-value": "key",
                      dense: "",
                      "hide-details": "",
                      outlined: "",
                    },
                    model: {
                      value: _vm.timezone,
                      callback: function ($$v) {
                        _vm.timezone = $$v
                      },
                      expression: "timezone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm2: "",
                    "px-4": "",
                    "pt-8": "",
                    "align-self-center": "",
                  },
                },
                [
                  _c("AppButton", {
                    attrs: {
                      "custom-class": "py-5",
                      label: "pages.settings.change_timezone",
                    },
                    on: { submit: _vm.changeTimezone },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("SuccessConfirmation", {
        attrs: { title: _vm.successTitle },
        on: {
          submit: function ($event) {
            return _vm.toggleSuccess(_vm.successType)
          },
        },
        model: {
          value: _vm.showSuccess,
          callback: function ($$v) {
            _vm.showSuccess = $$v
          },
          expression: "showSuccess",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }